import React, { useState, Fragment, useEffect } from 'react';
import EditPortalUserModal from './EditPortalUserModal';
import DataTable from 'react-data-table-component';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from '../../services/API';
import SweetAlert from 'sweetalert2';

const PortalUser = () => {
    const [state, setState] = useState({
        email: "",
        phone_no: "",
        staff_id: "",
        full_name: "",
        role: 2,
    });
    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state
    const [modalAddUser, setModalAddUser] = useState(false);

    const columns = [
        {
            name: "Name",
            selector: (row) => row.full_name,
            sortable: true,
            center: true,
        },
        {
            selector: (row) => row.email,
            name: "Email",
            sortable: true,
            center: true,
        },
        {
            selector: (row) => row.phone_no,
            name: "Phone Number",
            sortable: true,
            center: true,
        },
        {
            selector: (row) => (row.role === "1" ? "Admin" : "Staff"),
            name: "Role",
            sortable: true,
            center: true,
        },
        {
            selector: (row) => (
                <>
                    {/* <Button onClick={() => openEditModal(row)} color="primary" className="mr-2">
                        Edit
                    </Button> */}
                    {row.role!=='1'?<Button onClick={() => handleDelete(row)} color="danger">
                        Delete
                    </Button>:null}
                </>
            ),
            name: "Actions",
            sortable: false,
            center: true,
        },
    ];

    useEffect(() => {
        requestPortalUserList();
    }, []);

    const requestPortalUserList = async () => {
        setLoading(true); // Start loading
        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            token: localStorage.getItem('token'),
        };
        API.get('/schoolStaff/getStaff', { headers })
            .then((response) => {
                if (response.data.response_code === 1000) {
                    setEmployee(response.data.data);
                } else {
                    toast.error(response.data.message, { autoClose: 5000 });
                }
            })
            .catch(() => {
                toast.error('Failed to fetch staff list', { autoClose: 5000 });
            })
            .finally(() => setLoading(false)); // Stop loading
    };

    const handleSubmit = async () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!state.full_name) {
            toast.error("Full name is required", { autoClose: 3000 });
            return;
        }
        if (!emailPattern.test(state.email)) {
            toast.error("Invalid email address", { autoClose: 3000 });
            return;
        }

        setLoading(true); // Start loading
        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            token: localStorage.getItem('token'),
        };
        API.post('/schoolStaff/registerStaff', {
            email: state.email,
            phone_no: state.phone_no,
            role: state.role,
            staff_id: state.staff_id,
            full_name: state.full_name,
        }, { headers })
            .then((response) => {
                if (response.data.response_code === 1000) {
                    requestPortalUserList();
                    toggleModal();
                    SweetAlert.fire('', 'Successfully Registered', 'success');
                } else {
                    toast.error(response.data.message, { autoClose: 5000 });
                }
            })
            .catch(() => {
                toast.error("Failed to create staff", { autoClose: 5000 });
            })
            .finally(() => setLoading(false)); // Stop loading
    };

    const handleDelete = (row) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: `You are about to delete ${row.full_name}'s profile. This action cannot be undone.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteStaff(row);
            }
        });
    };

    const deleteStaff = (row) => {
        setLoading(true); // Start loading
        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            token: localStorage.getItem('token'),
        };
        API.delete('/schoolStaff/deleteStaff', {
            headers,
            data: { email: row.email }, // Assuming deletion by email
        })
            .then((response) => {
                if (response.data.response_code === 1000) {
                    SweetAlert.fire('', `${row.full_name} has been deleted successfully`, 'success');
                    requestPortalUserList();
                } else {
                    toast.error(response.data.message, { autoClose: 5000 });
                }
            })
            .catch(() => {
                toast.error("Failed to delete staff profile", { autoClose: 5000 });
            })
            .finally(() => setLoading(false)); // Stop loading
    };

    const toggleModal = () => {
        setModalAddUser(!modalAddUser);
        if (!modalAddUser) {
            setState({ email: "", phone_no: "", full_name: "", role: 2, staff_id: "" }); // Reset state
        }
    };

    const onChange = (field, event) => {
        const fieldValue = event.target.value;
        setState((prevState) => ({
            ...prevState,
            [field]: fieldValue,
        }));
    };

    const openEditModal = (row) => {
        toast.info("Edit functionality not implemented yet!", { autoClose: 3000 });
    };

    return (
        <Fragment>
            <EditPortalUserModal openModal={false} data={null} onSubmitSuccess={requestPortalUserList} />
            <div className="container-fluid">
                <div className="row">
                    <Modal isOpen={modalAddUser} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Add New Portal User</ModalHeader>
                        <ModalBody>
                            <form>
                                <div className="form-group">
                                    <label>Full Name</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={state.full_name}
                                        onChange={(e) => onChange('full_name', e)}
                                        placeholder="Full Name"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        className="form-control"
                                        type="email"
                                        value={state.email}
                                        onChange={(e) => onChange('email', e)}
                                        placeholder="Email"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={state.phone_no}
                                        onChange={(e) => onChange('phone_no', e)}
                                        placeholder="Phone Number"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Staff ID</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={state.staff_id}
                                        onChange={(e) => onChange('staff_id', e)}
                                        placeholder="Create for school staff ID"
                                    />
                                </div>
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" onClick={handleSubmit} disabled={loading}>
                                {loading ? "Submitting..." : "Create Account"}
                            </Button>
                            <Button color="danger" onClick={toggleModal}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header">
                                <Button onClick={toggleModal} color="primary">Add New Portal User</Button>
                            </div>
                            <div className="card-body">
                                <DataTable
                                    columns={columns}
                                    data={employee}
                                    striped
                                    pagination
                                    progressPending={loading}
                                    progressComponent={<div>Loading...</div>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer theme="dark" />
            </div>
        </Fragment>
    );
};

export default PortalUser;
